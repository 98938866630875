@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import url(https://fonts.googleapis.com/css?family=Roboto:300);
:root{
  --margin:10px;
  --padding:10px;
  --primary-color:blueviolet;
  --border-radius:5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rtl{
  direction: rtl;
}

.display-flex{
  display: flex;
}

.display-block{
  display: block;
}

.display-inline{
  display: inline;
}

.center{
  justify-items: center;
  justify-content: center;
  display: flex;
  
}

.pointer{
  cursor: pointer;
}

.text-black{
  color: black;
}

.color-primary{
  color: blueviolet;
  color: var(--primary-color);
}

.background-lightyellow{
  background-color: lightyellow;
}

.margin{
  margin: 10px;
  margin: var(--margin);
}

.horizontal-margin{
  margin-left: 10px;
  margin-left: var(--margin);
  margin-right: 10px;
  margin-right: var(--margin);
}

.padding{
  padding: 10px;
  padding: var(--padding);
}

.circular-border{
  border-radius: 5px;
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: blueviolet;
  border-color: var(--primary-color);
}

.justify-content-space-between{
  justify-content: space-between;
}

.width{
  width: --width;
}

.width-100-percent{
  width: 100%;
}
.App_App__3jbTN {
  text-align: center;
}

.App_section__rQ5j8 {
  /* width: calc(100% - 225px); */
  /* margin-right: 225px; */
  /* width: 100%; */
  transition: all 0.5s ease;
}

.App_fixed__wpJd2 {
  position: fixed;
  z-index: 1;
  top: 0;
}

.App_notification-container__24kUk {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999
}

.App_top-right__5damq {
  top: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: App_toast-in-right__SYYzH .7s;
}

.App_bottom-right__3yug- {
  bottom: 12px;
  right: 12px;
  transition: transform .6s ease-in-out;
  animation: App_toast-in-right__SYYzH .7s;
}

.App_top-left__H_f-B {
  top: 12px;
  left: 12px;
  transition: transform .6s ease-in;
  animation: App_toast-in-left__ey6jt .7s;
}

.App_bottom-left__1wt6E {
  bottom: 12px;
  left: 12px;
  transition: transform .6s ease-in;
  animation: App_toast-in-left__ey6jt .7s;
}
.SignIn_login-page__VxCo1 {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.SignIn_form__1_0ms {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.SignIn_form__1_0ms input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.SignIn_form__1_0ms button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4CAF50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}
.SignIn_form__1_0ms button:hover,.SignIn_form__1_0ms button:active,.SignIn_form__1_0ms button:focus {
  background: #43A047;
}
.SignIn_form__1_0ms .SignIn_message__lm4u6 {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.SignIn_form__1_0ms .SignIn_message__lm4u6 a {
  color: #4CAF50;
  text-decoration: none;
}
.SignIn_form__1_0ms .SignIn_register-form__3OOy- {
  display: none;
}
.SignIn_container__2vcg0 {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.SignIn_container__2vcg0:before, .SignIn_container__2vcg0:after {
  content: "";
  display: block;
  clear: both;
}
.SignIn_container__2vcg0 .SignIn_info__5EyBz {
  margin: 50px auto;
  text-align: center;
}
.SignIn_container__2vcg0 .SignIn_info__5EyBz h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.SignIn_container__2vcg0 .SignIn_info__5EyBz span {
  color: #4d4d4d;
  font-size: 12px;
}
.SignIn_container__2vcg0 .SignIn_info__5EyBz span a {
  color: #000000;
  text-decoration: none;
}
.SignIn_container__2vcg0 .SignIn_info__5EyBz span .SignIn_fa__Yr0S2 {
  color: #EF3B3A;
}
.SignIn_body__3zE4o {
  background: #e7ffdb; /* fallback for old browsers */
  background: linear-gradient(to left, #e7ffdb, #e7ffdb);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
.SignUp_login-page__2RZaw {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.SignUp_form__3_4MZ {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.SignUp_form__3_4MZ input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.SignUp_form__3_4MZ button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4CAF50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}
.SignUp_form__3_4MZ button:hover,.SignUp_form__3_4MZ button:active,.SignUp_form__3_4MZ button:focus {
  background: #43A047;
}
.SignUp_form__3_4MZ .SignUp_message__39Ovh {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.SignUp_form__3_4MZ .SignUp_message__39Ovh a {
  color: #4CAF50;
  text-decoration: none;
}
.SignUp_form__3_4MZ .SignUp_register-form__cREtv {
  display: none;
}
.SignUp_container__1q_1_ {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.SignUp_container__1q_1_:before, .SignUp_container__1q_1_:after {
  content: "";
  display: block;
  clear: both;
}
.SignUp_container__1q_1_ .SignUp_info__2Sfdj {
  margin: 50px auto;
  text-align: center;
}
.SignUp_container__1q_1_ .SignUp_info__2Sfdj h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.SignUp_container__1q_1_ .SignUp_info__2Sfdj span {
  color: #4d4d4d;
  font-size: 12px;
}
.SignUp_container__1q_1_ .SignUp_info__2Sfdj span a {
  color: #000000;
  text-decoration: none;
}
.SignUp_container__1q_1_ .SignUp_info__2Sfdj span .SignUp_fa__o_Ial {
  color: #EF3B3A;
}
.SignUp_body__CQ4jh {
  background: #e7ffdb; /* fallback for old browsers */
  background: linear-gradient(to left, #e7ffdb, #e7ffdb);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
 .Sidebar_sidebar__2wmn2 {
     background: rgb(5, 68, 104);
     position: fixed;
     top: 0;
     width: 225px;
     height: 100%;
     padding: 20px 0;
     transition: all 0.5s ease;
 }

 .Sidebar_sidebar__2wmn2 .Sidebar_profile__2sga9 {
     margin-bottom: 30px;
     text-align: center;
 }

 .Sidebar_sidebar__2wmn2 .Sidebar_profile__2sga9 img {
     display: block;
     width: 100px;
     height: 100px;
     border-radius: 50%;
     margin: 0 auto;
 }

 .Sidebar_sidebar__2wmn2 .Sidebar_profile__2sga9 h3 {
     color: #ffffff;
     margin: 10px 0 5px;
 }
li {
    display: block;
    padding: 13px 30px;
    border-bottom: 1px solid #10558d;
    color: rgb(241, 237, 237);
    font-size: 16px;
    position: relative;
    cursor: pointer;
}

.MenuItem_icon__1Qqjx {
    color: #dee4ec;
    width: 30px;
    display: inline-block;
}

li:hover,
li.MenuItem_active__3tyNk {
    color: #0c7db1;
    text-decoration: underline;
    background: white;
    border-right: 2px solid rgb(5, 68, 104);
}

li:hover .MenuItem_icon__1Qqjx,
li.MenuItem_active__3tyNk .MenuItem_icon__1Qqjx {
    color: #0c7db1;
}

li:hover:before,
li.MenuItem_active__3tyNk:before {
    display: block;
}
 .Navbar_top_navbar__27p-p {
     background: rgb(7, 105, 185);
     /* height: 50px; */
     display: flex;
     flex-flow: wrap;
     align-items: center;
     padding: 5px 30px;
     justify-content: space-between;
 }

 .Navbar_hamburger__3pnsL {
     font-size: 28px;
     color: #f4fbff;
 }

 .Navbar_hamburger__3pnsL:hover {
     color: #a2ecff;
     cursor: pointer;
 }
 .SecondaryNavBar_secondary_navbar__2tL76 {
     background: rgb(7, 105, 185);
     /* height: 50px; */
     display: flex;
     justify-content: space-between;
     padding: 10px 30px;
     flex-flow: wrap;
 }

 .SecondaryNavBar_group__F7Dg3 {
     display: flex;
     flex-flow: wrap;
     align-items: center;
 }
.Searchbar_search-box__1f2rW {
    height: 40px;
    border-radius: 40px;
    display: flex;
}

.Searchbar_search-box__1f2rW:hover {
    background-color: white;
}

.Searchbar_search-box__1f2rW:hover>.Searchbar_search-text__n_wh_ {
    width: 150px;
    padding: 0 6px;
}

.Searchbar_search-box__1f2rW:hover>.Searchbar_search-btn__OUJlK {
    background: white;
    color: rgb(7, 105, 185);
}

.Searchbar_search-btn__OUJlK {
    float: right;
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background: rgb(7, 105, 185);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    text-decoration: none;

}

/* .search-btn>i {
    fontsize: 30px;
} */

.Searchbar_search-text__n_wh_ {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: rgb(7, 105, 185);
    font-size: 16px;
    font-weight: normal;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
}
.Toast_notification-container__2h4gk {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999
}

.Toast_top-right__2nfe3 {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: Toast_toast-in-right__2LVzG .7s;
}

.Toast_bottom-right-fade__ejsbK,
.Toast_top-right-fade__3IslJ {
    transition: transform .6s ease-in-out;
    animation: Toast_toast-in-right-fade__2IfAW .7s;
    right: -385px;
}

.Toast_bottom-right__3f3Tx {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: Toast_toast-in-right__2LVzG .7s;
}

.Toast_top-left__17lNb {
    top: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: Toast_toast-in-left__K0pEz .7s;
}

.Toast_bottom-left-fade__1yRyB,
.Toast_top-left-fade__15oow {
    transition: transform .6s ease-in-out;
    animation: Toast_toast-in-left-fade__3zBHP .7s;
    left: -385px;
}

.Toast_bottom-left__3CGex {
    bottom: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: Toast_toast-in-left__K0pEz .7s;
}

.Toast_notification__13rPz {
    background: #fff;
    transition: .3s ease;
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 30px;
    margin-bottom: 15px;
    width: 300px;
    max-height: 100px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 10px #999;
    color: #000;
    opacity: .9;
    background-position: 15px;
    background-repeat: no-repeat;
}

.Toast_notification__13rPz:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
    cursor: pointer
}

.Toast_notification-title__7Pcea {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 300px;
    height: 18px;
}

.Toast_notification-message__15l2R {
    margin: 0;
    text-align: left;
    height: 18px;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Toast_notification-image__3nsMB {
    float: left;
    margin-right: 15px;
}

.Toast_notification-image__3nsMB .Toast_img__1w-YK {
    width: 30px;
    height: 30px;
}

.Toast_toast__3E-j_ {
    /* height: 50px; */
    width: 365px;
    color: #fff;
    padding: 20px 15px 10px 10px;
}

.Toast_button__6qHyM {
    position: relative;
    right: -.3em;
    top: -.3em;
    float: right;
    font-weight: 700;
    color: #fff;
    outline: none;
    border: none;
    text-shadow: 0 1px 0 #fff;
    opacity: .8;
    line-height: 1;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
}

@keyframes Toast_toast-in-right__2LVzG {
    from {
        transform: translateX(100%);

    }

    to {
        transform: translateX(0);
    }
}

@keyframes Toast_toast-in-right-fade__2IfAW {
    from {
        transform: translateX();

    }

    to {
        transform: translateX(100%);
    }
}

@keyframes Toast_toast-in-left__K0pEz {
    from {
        transform: translateX(-100%);

    }

    to {
        transform: translateX(0);
    }
}

@keyframes Toast_toast-in-left-fade__3zBHP {
    from {
        transform: translateX(0);

    }

    to {
        transform: translateX(-100);
    }
}

.Toast_success__2JL1F {
    background-color: #5cb85c;
}
.Loading_html-progress__NlU9T {
    background-color: lightgray;
    width: 100%;
    height: 4px;
}

.Loading_progress-value__2EAs1 {
    background-color: rgb(5, 68, 104);
    height: 100%;
    animation: Loading_grow__3F0Hg 1s linear forwards;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

/* animation */
@keyframes Loading_grow__3F0Hg {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}


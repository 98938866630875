 .secondary_navbar {
     background: rgb(7, 105, 185);
     /* height: 50px; */
     display: flex;
     justify-content: space-between;
     padding: 10px 30px;
     flex-flow: wrap;
 }

 .group {
     display: flex;
     flex-flow: wrap;
     align-items: center;
 }
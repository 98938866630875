.search-box {
    height: 40px;
    border-radius: 40px;
    display: flex;
}

.search-box:hover {
    background-color: white;
}

.search-box:hover>.search-text {
    width: 150px;
    padding: 0 6px;
}

.search-box:hover>.search-btn {
    background: white;
    color: rgb(7, 105, 185);
}

.search-btn {
    float: right;
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background: rgb(7, 105, 185);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    text-decoration: none;

}

/* .search-btn>i {
    fontsize: 30px;
} */

.search-text {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: rgb(7, 105, 185);
    font-size: 16px;
    font-weight: normal;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
}